import bg0 from '../../../assets/images/gk/bg5.png';
import bg1 from '../../../assets/images/gk/bg6.png';
import bg2 from '../../../assets/images/gk/bg7.png';
import bg3 from '../../../assets/images/gk/bg8.png';
import bg5 from '../../../assets/images/gk/bg10.png';

import lbg1 from '../../../assets/images/gk/lbg1.png';
import lbg2 from '../../../assets/images/gk/lbg2.png';
import lbg3 from '../../../assets/images/gk/lbg3.png';
import lbg4 from '../../../assets/images/gk/lbg4.png';
import lbg5 from '../../../assets/images/gk/lbg5.png';
// eslint-disable-next-line complexity
function addBgImages(index, position) {
    let str = '';
    if (position === 'top') {
        switch (index) {
            case 0:
                str = bg1;
                break;
            case 1:
                str = bg0;
                break;
            case 2:
                str = bg5;
                break;
            case 3:
                str = bg2;
                break;
            case 5:
                str = bg0;
                break;
            case 4:
                str = bg1;
                break;
            case 6:
                str = bg5;
                break;
            case 7:
                str = bg2;
                break;
            case 8:
                str = bg1;
                break;
            case 9:
                str = bg0;
                break;
            case 10:
                str = bg5;
                break;
            case 11:
                str = bg2;
                break;
        }
    } else if (position === 'right') {
        switch (index) {
            case 0:
                str = lbg1;
                break;
            case 1:
                str = lbg2;
                break;
            case 2:
                str = lbg3;
                break;
            case 3:
                str = lbg4;
                break;
            case 5:
                str = lbg5;
                break;
            case 4:
                str = lbg1;
                break;
            case 6:
                str = lbg2;
                break;
            case 7:
                str = lbg3;
                break;
            case 8:
                str = lbg4;
                break;
            case 9:
                str = lbg5;
                break;
            case 10:
                str = lbg1;
                break;
            case 11:
                str = lbg2;
                break;
        }
    }
    return str;
}
function heckAllCnText(str) {
    const reg = /[\u4E00-\u9FA5]/g;
    if (reg.test(str)) {
        const aaa = str.replace(reg, '-');
        return aaa.slice(0, aaa.length - 1);
    }
    return str;
}
export { addBgImages, heckAllCnText };